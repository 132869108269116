exports.components = {
  "component---src-components-layouts-adj-conjugation-js": () => import("./../../../src/components/layouts/adj_conjugation.js" /* webpackChunkName: "component---src-components-layouts-adj-conjugation-js" */),
  "component---src-components-layouts-cheatsheet-js": () => import("./../../../src/components/layouts/cheatsheet.js" /* webpackChunkName: "component---src-components-layouts-cheatsheet-js" */),
  "component---src-components-layouts-genki-sentence-lesson-js": () => import("./../../../src/components/layouts/genki_sentence_lesson.js" /* webpackChunkName: "component---src-components-layouts-genki-sentence-lesson-js" */),
  "component---src-components-layouts-kanji-js": () => import("./../../../src/components/layouts/kanji.js" /* webpackChunkName: "component---src-components-layouts-kanji-js" */),
  "component---src-components-layouts-verb-conjugation-js": () => import("./../../../src/components/layouts/verb_conjugation.js" /* webpackChunkName: "component---src-components-layouts-verb-conjugation-js" */),
  "component---src-components-layouts-vocab-js": () => import("./../../../src/components/layouts/vocab.js" /* webpackChunkName: "component---src-components-layouts-vocab-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-projects-japanese-counters-js": () => import("./../../../src/pages/projects/japanese/counters.js" /* webpackChunkName: "component---src-pages-projects-japanese-counters-js" */),
  "component---src-pages-projects-japanese-days-js": () => import("./../../../src/pages/projects/japanese/days.js" /* webpackChunkName: "component---src-pages-projects-japanese-days-js" */),
  "component---src-pages-projects-japanese-genki-index-js": () => import("./../../../src/pages/projects/japanese/genki/index.js" /* webpackChunkName: "component---src-pages-projects-japanese-genki-index-js" */),
  "component---src-pages-projects-japanese-genki-vocab-9-x-2-be-ypnepl-js": () => import("./../../../src/pages/projects/japanese/genki/vocab9-X2BEYpnepl.js" /* webpackChunkName: "component---src-pages-projects-japanese-genki-vocab-9-x-2-be-ypnepl-js" */),
  "component---src-pages-projects-japanese-index-js": () => import("./../../../src/pages/projects/japanese/index.js" /* webpackChunkName: "component---src-pages-projects-japanese-index-js" */),
  "component---src-pages-projects-japanese-kana-js": () => import("./../../../src/pages/projects/japanese/kana.js" /* webpackChunkName: "component---src-pages-projects-japanese-kana-js" */),
  "component---src-pages-projects-japanese-na-vs-no-js": () => import("./../../../src/pages/projects/japanese/na-vs-no.js" /* webpackChunkName: "component---src-pages-projects-japanese-na-vs-no-js" */),
  "component---src-pages-projects-japanese-numbers-js": () => import("./../../../src/pages/projects/japanese/numbers.js" /* webpackChunkName: "component---src-pages-projects-japanese-numbers-js" */),
  "component---src-pages-projects-japanese-patreon-js": () => import("./../../../src/pages/projects/japanese/patreon.js" /* webpackChunkName: "component---src-pages-projects-japanese-patreon-js" */),
  "component---src-pages-projects-japanese-time-js": () => import("./../../../src/pages/projects/japanese/time.js" /* webpackChunkName: "component---src-pages-projects-japanese-time-js" */),
  "component---src-pages-projects-japanese-transitive-js": () => import("./../../../src/pages/projects/japanese/transitive.js" /* webpackChunkName: "component---src-pages-projects-japanese-transitive-js" */),
  "component---src-pages-projects-japanese-transitive-or-intransitive-js": () => import("./../../../src/pages/projects/japanese/transitive-or-intransitive.js" /* webpackChunkName: "component---src-pages-projects-japanese-transitive-or-intransitive-js" */)
}

